@import '../config/colors';
@import '../mixins/typography';
@import '../mixins/container';
@import '../mixins/sr-only';

.social {
  $self: &;

  &__title {
    color: $c-darkblue;
    font-size: 1.125rem;
    font-weight: $weight-normal;
    line-height: 2.5rem;
  }

  &__item {
    display: block;
    margin: 0 0 1rem;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__link {
    display: block;
    font-size: 1.125rem;
    line-height: 24px;
    text-decoration: none;

    &:hover {
      #{ $self }__icon {
        color: currentColor;
      }

      #{ $self }__text {
        border-bottom-color: currentColor;
      }
    }
  }

  &__text {
    border-bottom: 2px solid transparent;
    transition: border-bottom-color .25s ease;
  }

  &__icon {
    display: inline-block;
    fill: $c-white;
    height: 24px;
    margin-right: .7rem;
    transition: fill .2s;
    vertical-align: -6px;
    width: 24px;
  }
}
