@import '../config/colors';
@import '../base/fonts';
@import '../config/sizes';
@import '../mixins/screen';
@import '../mixins/typography';

.footer {
  $self: &;

  &__column {
    margin: 0 0 2rem;
  }

  &__title {
    @include style-heading;
    color: $c-white;
    font-size: 2rem;
    font-weight: $weight-bold;
    margin: 0 0 1.5rem;
  }

  &__content {
    p {
      font-size: 1.125rem;
      margin: 0 0 .75rem;

      &:last-child {
        margin-bottom: 0;
      }
    }

    a {
      &:not([class]) {
        color: $c-darkblue;
        font-weight: $weight-normal;
      }
    }
  }

  @include screen($screen-simple) {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.5rem;

    &__column {
      flex: 0 0 50%;
      padding: 0 1.5rem;
      width: 50%;
    }
  }

  @include screen($screen-normal) {
    flex-wrap: nowrap;

    &__column {
      flex-basis: 25%;
      margin: 0;
      width: 25%;
    }
  }

  @include screen($screen-xlarge) {
    margin: 0 -2rem;

    &__column {
      padding: 0 2rem;
    }
  }
}
