$p-gutter: 40px;
$p-gutter--small: 20px;

$screen-minimal: 480px;
$screen-medium: 600px;
$screen-simple: 900px;
$screen-normal: 1024px;
$screen-large: 1200px;
$screen-xlarge: 1536px;
$screen-jumbo: 1920px;

$w-site: 100%;
$h-menu: 80px;
$h-menu-desktop: 140px;

$w-section-container: 1300px;
$w-section-content: 980px;
$w-menu-container: 1820px;
$w-content: 1920px;

$r-button: 30px 10px 30px 30px;
$r-button--xlarge: 42px 10px 42px 42px;

$r-button--reverse: 30px 10px 30px 30px;
$r-button--xlarge-reverse: 42px 10px 42px 42px;

$h-button--small: 48px;
$h-button: 54px;
$h-button--large: 76px;
$h-button--xlarge: 92px;

$p-button: 1.65rem;

$h-field: 60px;
