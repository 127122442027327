@import '../config/colors';
@import '../config/sizes';
@import '../mixins/container';
@import '../mixins/screen';
@import '../mixins/sr-only';

.legal {
  $self: &;
  align-items: center;
  background-color: $c-darkblue;
  display: flex;
  flex-direction: column;
  padding: 2rem $p-gutter--small;

  &__container {
    text-align: center;
    width: 100%;
  }

  &__logo {
    align-content: center;
    color: $c-white;
    display: block;
    height: auto;
    margin: 0 auto;
    transition: transform .2s;
    width: 140px;

    &:hover {
      transform: scale(1.1);
    }
  }

  &__content {
    color: $c-white;
    font-size: 1.125rem;
    padding-bottom: 1.7rem;
  }

  &__copyright {
    display: block;
    margin-bottom: .5rem;
  }

  &__menu-item {
    margin-bottom: 4px;
  }

  &__link {
    border-bottom: 1px solid transparent;
    color: $c-white;
    padding-top: 1px; // compensate for border
    text-decoration: none;
    transition: border-bottom-color .2s ease-in;

    &:hover {
      border-bottom-color: currentColor;
    }
  }

  @include screen($screen-minimal) {
    padding: 3rem $p-gutter;
  }

  @include screen(820px) {
    &__container {
      align-items: center;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      text-align: left;
    }

    &__logo {
      margin: 0;
    }

    &__menu {
      display: flex;
    }

    &__menu-item {
      margin: 0;

      &::before {
        content: '|';
        margin: 0 .2rem 0 .4rem;
      }

      &:first-child {
        &::before {
          display: none;
        }
      }
    }
  }

  @include screen($screen-large) {
    &__content {
      display: flex;
      padding: 0 20px 0 0;
    }

    &__menu-item {
      &:first-child {
        &::before {
          display: inline;
        }
      }
    }
  }
}
