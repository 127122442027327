@import '../config/colors';
@import '../base/fonts';
@import '../config/sizes';
@import '../mixins/screen';

@mixin button-base {
  border-radius:  $r-button;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  display: inline-block;
  font-family: $font-source-sans-pro;
  font-size: 1.125rem;
  font-weight: $weight-medium;
  height: $h-button;
  line-height: $h-button - 2px;
  margin: 0;
  overflow: hidden;
  padding: 0 $p-button;
  position: relative;
  text-align: center;
  text-decoration: none;
  text-overflow: ellipsis;
  transition: background-color .2s, border-color .2s, color .2s, fill .2s;
  vertical-align: middle;
  white-space: nowrap;
  width: auto;
}

@mixin button-style(
  $background, $border, $color,
  $background-hover, $border-hover, $color-hover,
  $background-disabled: $background, $border-disabled: $border, $color-disabled: transparentize($color, .4)
) {
  background-color: $background;
  border-color: $border;
  color: $color;
  fill: $color;

  &:focus,
  &:hover {
    background-color: $background-hover;
    border-color: $border-hover;
    color: $color-hover;
    fill: $color-hover;
  }

  &:disabled {
    background-color: $background-disabled;
    border-color: $border-disabled;
    color: $color-disabled;
    cursor: not-allowed;
    fill: $color-disabled;
  }
}
