@import '../config/colors';
@import '../config/sizes';

.site {
  $self: &;

  background-color: $white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;

  &__header,
  &__content,
  &__footer {
    margin: 0 auto;
    max-width: $w-site;
    width: 100%;
  }

  &__header {
    height: $h-menu;
  }

  &__content {
    flex: initial;
    flex-grow: 1;
    margin-top: 80px;
    background-color: $white;
    display: flex;
    flex-direction: column;

    footer {
      margin-bottom: 4rem;
    }
  }

  &__footer {
    flex: none;
    flex-grow: 0;
    position: relative;
  }

  &--darkblue {
    background-color: $secondary;
  }

  &--landing {
    background-color: $secondary;
    display: flex;
    min-height: 100vh;

    #{ $self }__header {
      left: 0;
      position: absolute;
      right: 0;
      top: 0;
      width: 100%;
      z-index: 10;
    }
  }

  @media only screen and (min-width: $screen-large) {
    &__header {
      height: $h-menu-desktop;
    }
  }
}
