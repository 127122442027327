@import '../config/colors';
@import '../config/sizes';
@import '../mixins/sr-only';

.hamburger {
  $self: &;

  background-color: transparent;
  border: 1px solid transparentize($c-darkblue, .8);
  border-radius: 50%;
  color: $c-darkblue;
  cursor: pointer;
  display: block;
  height: $h-button;
  outline: 0;
  position: relative;
  transition: background-color .3s, transform .3s ease, border-color .3s;
  width: $h-button;

  &__text {
    @include sr-only;
  }

  &__line {
    background-color: $c-darkblue;
    border-radius: 5px;
    height: 2px;
    left: 0;
    margin-left: auto;
    margin-right: auto;
    position: absolute;
    right: 0;
    text-align: center;
    transition: width .3s, background-color .3s;
    width: 24px;

    &:nth-child(1) {
      margin-top: -8px;
      transition: margin-top .3s ease, opacity .2s;
    }

    &:nth-child(2) {
      transition: margin-top .3s ease;
    }

    &:nth-child(3) {
      margin-top: 8px;
      transition: margin-top .3s ease, transform .3s ease;
    }
  }

  &--white {
    border-color: $c-white;

    #{ $self }__line {
      background-color: $c-white;
    }
  }

  &.is-open {
    border-color: transparentize($c-darkblue, .95);
    transform: rotate(225deg);
    transition-delay: .09s;
    transition-timing-function: cubic-bezier(.215, .61, .355, 1);
  }

  .is-open & {

    &__line {

      &:nth-child(1) {
        margin-top: -2px;
        opacity: 0;
        transition: margin-top .3s ease-out, opacity .3s;
      }

      &:nth-child(2) {
        margin-top: -2px;
      }

      &:nth-child(3) {
        margin-top: -2px;
        transform: rotate(-90deg);
        transition: margin-top .3s, transform .3s cubic-bezier(.215, .61, .355, 1) .12s;
      }
    }
  }
}
