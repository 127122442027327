@import '../mixins/clear-fix';
@import '../mixins/container';
@import '../mixins/typography';
@import '../config/colors';
@import '../mixins/stretch';

.section {
  $self: &;

  background-color: $c-white;
  padding: 3rem 0;
  position: relative;

  &__header,
  &__content,
  &__container,
  &__footer {
    @include container($w-section-container);
    padding: 0 $p-gutter--small;
    position: relative;

    &--double-text {
      &::after {
        background-color: $c-yellow;
        content: '';
        display: block;
        height: 4px;
        margin: 2rem auto 0;
        width: 100px;
      }
    }
  }

  &__header {
    margin: 0 auto;
    max-width: $w-section-content;
    text-align: center;

    & + #{ $self }__content,
    & + #{ $self }__container {
      margin-top: 3rem;
    }

    &::after {
      background-color: $c-yellow;
      content: '';
      display: block;
      height: 4px;
      margin: 2rem auto 0;
      width: 100px;
    }
  }

  &__content {
    @include clear-fix;
    max-width: $w-section-content;
  }

  &__footer {
    margin-top: 1rem;
    text-align: center;
  }

  &__title {
    @include style-heading;
    @include style-heading-1;
    margin: 0 auto 1rem;
    max-width: 900px;
    position: relative;

    & + #{ $self }__description {
      margin-top: -.5rem; // less margin between title & description
    }
  }

  &__intro {
    color: $c-darkblue;
    margin: 0 auto;
    max-width: 740px;
    position: relative;
    text-align: center;
  }

  &__wave {
    display: none;
  }

  // Color modifiers
  &--white {
    + #{ $self }--white {
      padding-top: 0;
    }
  }

  &--grey {
    background-color: $c-grey--lightest;

    + #{ $self }--grey {
      padding-top: 0;
    }
  }

  &--darkblue {
    background-color: $c-darkblue;
    color: $c-white;

    + #{ $self }--darkblue {
      padding-top: 0;
    }

    #{ $self }__header {
      &::after {
        background-color: $c-white;
      }
    }

    #{ $self }__title,
    #{ $self }__intro {
      color: $c-white;
    }
  }

  &--greyblue {
    background-color: $c-greyblue;
    color: $c-white;

    + #{ $self }--greyblue {
      padding-top: 0;
    }

    #{ $self }__header {
      &::after {
        background-color: $c-white;
      }
    }

    #{ $self }__title,
    #{ $self }__intro {
      color: $c-white;
    }
  }

  &--yellow {
    background-color: $c-yellow;

    + #{ $self }--yellow {
      padding-top: 0;
    }

    #{ $self }__header {
      &::after {
        background-color: $c-darkblue;
      }
    }

    #{ $self }__intro {
      a {
        &:not([class]) {
          color: $c-darkblue;
        }
      }
    }
  }

  // Other modifiers

  &--wide {
    #{ $self }__container {
      max-width: $w-site;
    }
  }

  &--left {
    #{ $self }__header {
      max-width: $w-content + (2 * $p-gutter);
      text-align: left;

      &::after {
        margin-left: 0;
        margin-right: 0;
      }
    }

    #{ $self }__title,
    #{ $self }__intro {
      margin-left: 0;
      margin-right: 0;
      text-align: left;
    }
  }

  // Specials

  &--games {
    #{ $self }__header {
      max-width: $w-section-container;

      &::after {
        display: none;
      }

      & + #{ $self }__content,
      & + #{ $self }__container {
        margin-top: 2rem;
      }
    }

    #{ $self }__title {
      @include style-heading-3;
    }
  }

  &--highlight {
    #{ $self }__header {
      max-width: $w-section-container;

      &::after {
        display: none;
      }
    }

    #{ $self }__title {
      @include style-heading-article;
      margin-left: 0;
      text-align: left;
    }

    #{ $self }__intro {
      margin-left: 0;
    }

    &#{ $self }--yellow {
      #{ $self }__title,
      #{ $self }__intro {
        color: $c-white;
      }
    }
  }

  &--search,
  &--related {
    #{ $self }__title {
      @include style-heading-2;
    }
  }

  // TODO: Updated styling, but perhaps replace em with span in backend?
  &--search-result p em.highlight {
    font-style: normal;
    font-weight: bolder;
    color: $c-darkblue;
    display: inline-block;
  }

  &--search-result h2 em.highlight {
    font-style: normal;
  }

  &--newsletter {
    #{ $self }__header {
      max-width: $w-content;

      &::after {
        display: none;
      }

      & + #{ $self }__content {
        margin-top: 1.5rem;
      }
    }

    #{ $self }__title {
      color: $c-white;
    }

    #{ $self }__content {
      max-width: $w-content;
    }

    &::after {
      background: transparentize($c-white, .58);
      content: '';
      display: block;
      height: 2px;
      margin: 3.5rem auto 0;
      max-width: $w-section-container;
      width: 100%;
    }

  }

  @include screen($screen-minimal) {
    &__header,
    &__content,
    &__container,
    &__footer {
      padding: 0 $p-gutter;
    }
  }

  @include screen($screen-simple) {
    padding-bottom: 4rem;
    padding-top: 4rem;

    &__header {
      & + #{ $self }__content,
      & + #{ $self }__container {
        margin-top: 4rem;
      }
    }

    &__footer {
      margin-top: 2rem;
    }

    &--white {
      + #{ $self }--white {
        padding-top: 0;
      }
    }

    &--grey {
      + #{ $self }--grey {
        padding-top: 0;
      }
    }

    &--darkblue {
      + #{ $self }--darkblue {
        padding-top: 0;
      }
    }

    &--greyblue {
      + #{ $self }--greyblue {
        padding-top: 0;
      }
    }

    &--yellow {
      + #{ $self }--yellow {
        padding-top: 0;
      }
    }
  }

  @include screen($screen-normal) {
    &__wave {
      display: block;
      left: 0;
      opacity: .6;
      pointer-events: none;
      position: absolute;
      top: -48px;
      width: 100%;
    }

    &--highlight {
      padding-bottom: 6rem;
      padding-top: 6rem;

      #{ $self }__title {
        padding-left: 2.7rem;
        text-indent: -2.7rem;
      }
    }

    &--yellow,
    &--darkblue,
    &--greyblue {
      #{ $self }__wave {
        .wave {
          stroke: $c-white;
        }
      }
    }
  }

  @include screen($screen-large) {
    padding-bottom: 6rem;
    padding-top: 6rem;

    &__wave {
      left: -70px;
      top: -70px;
    }

    &--white {
      + #{ $self }--white {
        padding-top: 2rem;
      }
    }

    &--grey {
      + #{ $self }--grey {
        padding-top: 2rem;
      }
    }

    &--darkblue {
      + #{ $self }--darkblue {
        padding-top: 2rem;
      }
    }

    &--greyblue {
      + #{ $self }--greyblue {
        padding-top: 2rem;
      }
    }

    &--yellow {
      + #{ $self }--yellow {
        padding-top: 0;
      }
    }

    &--highlight {
      padding-bottom: 8rem;
      padding-top: 8rem;
    }

    &--games {
      padding-top: 4rem;
    }
  }

  @include screen($screen-xlarge) {
    &__wave {
      top: -110px;
    }

    &--highlight {
      padding-bottom: 12rem;
      padding-top: 12rem;
    }
  }
}
