@mixin clear-fix {
  &::before,
  &::after {
    content: ' '; /* 1 */
    display: table; /* 2 */
  }

  &::after {
    clear: both;
  }

  .legacy & {

    &::before,
    &::after {
      zoom: 1;
    }
  }
}
