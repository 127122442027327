@import '../config/colors';
@import '../config/sizes';
@import '../mixins/button';
@import '../mixins/center';
@import '../mixins/screen';
@import '../mixins/sr-only';
@import '../mixins/svg';

.button {
  $self: &;

  @include button-base;
  @include button-style($c-darkblue, $c-darkblue, $c-white, $c-yellow, $c-yellow, $c-darkblue);
  align-items: center;
  display: inline-flex;
  justify-content: center;

  &__text {
    display: block;
    flex: 0 0 auto;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    & + #{ $self }__icon {
      margin-left: .5rem;
    }
  }

  &__icon {
    display: block;
    flex: 0 0 auto;
    height: 14px;
    vertical-align: -7%;
    width: 14px;

    &--large {
      height: 24px;
      width: 24px;
    }

    & + #{ $self }__text {
      margin-left: .5rem;
    }
  }

  // SIZES
  &--small {
    font-weight: $weight-normal;
    height: $h-button--small;
    line-height: $h-button--small - 2px;
  }

  &--large {
    font-size: 1.5rem;
    height: $h-button--large;
    line-height: $h-button--large - 2px;
  }

  &--xlarge {
    border-radius: $r-button--xlarge;
    font-size: 1.5rem;
    height: $h-button--xlarge;
    line-height: $h-button--xlarge - 2px;

    #{ $self }__icon {
      height: 34px;
      width: 34px;
    }

    &#{ $self }--reverse {
      border-radius: $r-button--xlarge-reverse;
    }
  }

  // THEMES
  &--darkblue-white {
    @include button-style($c-darkblue, $c-darkblue, $c-white, $c-white, $c-white, $c-darkblue);
  }

  &--yellow {
    @include button-style($c-yellow, $c-yellow, $c-white, $c-darkblue, $c-darkblue, $c-yellow);
  }

  &--white {
    @include button-style($c-white, $c-white, $c-darkblue, $c-yellow, $c-yellow, $c-darkblue);
  }

  &--greyblue {
    @include button-style($c-greyblue, $c-greyblue, $c-white, $c-yellow, $c-yellow, $c-darkblue);
  }

  &--outline {
    @include button-style(transparent, $c-darkblue, $c-darkblue, $c-yellow, $c-yellow, $c-darkblue);
  }

  &--white-outline {
    @include button-style(transparent, $c-white, $c-white, $c-yellow, $c-yellow, $c-white);
  }

  // TYPES
  &--icon {
    width: $h-button;

    #{ $self }__icon {
      @include center;
      margin: 0;
    }

    #{ $self }__text {
      @include sr-only;
      margin: 0;
    }

    &#{ $self }--large {
      width: $h-button--large;
    }

    &#{ $self }--xlarge {
      width: $h-button--xlarge;
    }
  }

  &--icon-large {
    #{ $self }__icon {
      height: 24px;
      width: 24px;
    }
  }

  &--reverse {
    border-radius: $r-button--reverse;
  }

  &--round {
    border-radius: 50%;
  }

  &--animate {
    #{ $self }__text {
      & + #{ $self }__icon {
        margin-left: 0;
      }
    }

    #{ $self }__icon {
      display: none;

      & + #{ $self }__text {
        margin-left: 0;
      }
    }
  }

  @include screen($screen-normal) {
    &--animate {
      #{ $self }__icon {
        display: block;
        left: 1.2rem;
        position: absolute;
        transition: opacity .4s 0s;
      }

      #{ $self }__text {
        max-width: 0;
        opacity: 0;
        transition: opacity .4s 0s, max-width .4s 0s;
      }

      .in-view & {
        #{ $self }__icon {
          opacity: 0;
          transition: opacity .4s .2s; // in view animation
        }

        #{ $self }__text {
          max-width: 340px;
          opacity: 1;
          text-overflow: unset;
          transition: opacity .5s .2s, max-width .5s .2s; // in view animation
        }
      }
    }
  }
}
