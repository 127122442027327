@import '../config/sizes';
@import '../base/fonts';
@import '../mixins/screen';

//
// Text styles
//
@mixin style-text {
  font-size: 1.125rem;
  letter-spacing: 0.25px;
  line-height: 1.5;
}

@mixin style-text-small {
  font-size: 1rem;
}

@mixin style-monospaced {
  font-family: $font-monospace;
  font-size: 1.125rem;
  line-height: 1.5;
}

//
// Header Styles + 1 ~ 6
//
@mixin style-heading {
  font-family: $font-source-serif-pro;
  font-weight: $weight-bold;
  line-height: 1.2;
}

@mixin style-heading-1 {
  font-size: 2.5rem;

  @include screen($screen-simple) {
    font-size: 3rem;
  }
}

@mixin style-heading-2 {
  font-size: 2rem;
  letter-spacing: 0.25px;

  @include screen($screen-simple) {
    font-size: 2.5rem;
  }
}

@mixin style-heading-3 {
  font-size: 1.75rem;
  letter-spacing: 0.25px;

  @include screen($screen-simple) {
    font-size: 2rem;
  }
}

@mixin style-heading-4 {
  font-size: 1.5rem;
  font-weight: $weight-medium;

  @include screen($screen-simple) {
    font-size: 1.75rem;
  }
}

@mixin style-heading-5 {
  font-size: 1.25rem;
  font-weight: $weight-medium;

  @include screen($screen-simple) {
    font-size: 1.5rem;
  }
}

@mixin style-heading-6 {
  font-size: 1.25rem;
  font-weight: $weight-medium;
  letter-spacing: 0.15px;
}

//
// Specific Styles + 1 ~ 6
//
@mixin style-heading-header {
  font-size: 2.875rem;

  @include screen($screen-simple) {
    font-size: 3.625rem;
  }

  @include screen($screen-normal) {
    font-size: 4.5rem;
  }

  @include screen($screen-large) {
    font-size: 5rem;
  }

  @include screen($screen-xlarge) {
    font-size: 5.5rem;
  }
}

@mixin style-heading-header-small {
  font-size: 2.875rem;

  @include screen($screen-simple) {
    font-size: 3.625rem;
  }

  @include screen($screen-normal) {
    font-size: 4.5rem;
  }

  @include screen($screen-large) {
    font-size: 5rem;
  }
}

@mixin style-heading-subtitle {
  font-family: $font-source-sans-pro;
  font-size: 1.9375rem;
  font-weight: $weight-light;
  line-height: 1.2;

  @include screen($screen-simple) {
    font-size: 2.5rem;
  }
}

@mixin style-heading-subtitle-small {
  @include style-heading-subtitle;
  font-size: 1.5rem;

  @include screen($screen-simple) {
    font-size: 1.75rem;
  }
}

@mixin style-heading-article {
  font-size: 2rem;

  @include screen($screen-minimal) {
    font-size: 2.25rem;
  }

  @include screen($screen-simple) {
    font-size: 3rem;
  }

  @include screen($screen-normal) {
    font-size: 3.5rem;
  }

  @include screen($screen-xlarge) {
    font-size: 4rem;
  }
}

@mixin style-heading-article-game {
  font-size: 2rem;

  @include screen($screen-minimal) {
    font-size: 2.25rem;
  }

  @include screen($screen-simple) {
    font-size: 3.5rem;
  }

  @include screen($screen-normal) {
    font-size: 4.5rem;
  }

  @include screen($screen-large) {
    font-size: 5rem;
  }
}

@mixin style-heading-small {
  font-family: $font-source-sans-pro;
  font-size: 1rem;
  font-style: italic;
  opacity: 0.6;
}
