@mixin sr-only {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
}

@mixin undo-sr-only {
  clip: unset;
  height: auto;
  overflow: unset;
  position: static;
  width: auto;
}
