@import 'swiper/scss';
@import 'swiper/scss/navigation';
@import 'swiper/css/free-mode';

@import './margin';

// Form
.form {
  &__field {
    margin: 0 0 1rem;
  }

  &__buttons {
    display: flex;
  }
}

article {
  ul:last-child,
  p:last-child {
    margin-bottom: 0;
  }
}

// Links
.custom {
  &__link {
    font-weight: 700;
    text-decoration: none;
    border-bottom: 2px solid transparent;
    transition: border-bottom-color 0.25s ease;

    &:hover {
      border-bottom-color: currentColor;
    }
  }
}

// Hide arrows from input numbers
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

// Zendesk anchor offset
:target::before {
  content: '';
  display: block;
  height: 80px;
  margin-top: -80px;
}

// Swiper
:root {
  --swiper-navigation-size: 1rem;
  --swiper-navigation-color: $c-darkblue;
}

.swiper-slide {
  opacity: 0.25;
  transition: opacity 500ms ease;

  &-visible {
    opacity: 1;
  }
}

.swiper-slide[data-class='h-auto'] {
  height: auto;
}

.swiper-button-prev,
.swiper-button-next {
  background-color: $c-yellow;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
  width: calc(var(--swiper-navigation-size) * 2);
  height: calc(var(--swiper-navigation-size) * 2.5);
  top: calc(50% - 2.5rem);
  &.swiper-button-disabled {
    visibility: hidden;
  }
  &:after {
    font-weight: bold;
  }
}

.swiper-button-prev,
.swiper-rtl .swiper-button-next {
  &:after {
    content: 'prev';
  }
  left: 0;
  border-top-right-radius: calc(var(--swiper-navigation-size) / 4);
  border-bottom-right-radius: calc(var(--swiper-navigation-size) / 4);
}

.swiper-button-next,
.swiper-rtl .swiper-button-prev {
  &:after {
    content: 'next';
  }
  right: 0;
  border-top-left-radius: calc(var(--swiper-navigation-size) / 4);
  border-bottom-left-radius: calc(var(--swiper-navigation-size) / 4);
}

// Cookiebot
#CookiebotWidget {
  z-index: 1050 !important; // MUI z-index for Floating Action Button
}
