// Source Sans Pro
@import '~@fontsource/source-sans-pro/300.css';
@import '~@fontsource/source-sans-pro/400.css';
@import '~@fontsource/source-sans-pro/400-italic.css';
@import '~@fontsource/source-sans-pro/600.css';
@import '~@fontsource/source-sans-pro/700.css';

// Source Serif Pro
@import '~@fontsource/source-serif-pro/400.css';
@import '~@fontsource/source-serif-pro/600.css';
@import '~@fontsource/source-serif-pro/700.css';


$weight-light: 300;
$weight-normal: 400;
$weight-medium: 600;
$weight-bold: 700;

$font-source-sans-pro: "Source Sans Pro", sans-serif;
$font-source-serif-pro: "Source Serif Pro", serif;
$font-monospace: 'Courier New';
