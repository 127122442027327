@import '../config/colors';
@import '../config/sizes';
@import '../mixins/button';
@import '../mixins/list-reset';
@import '../mixins/screen';
@import '../mixins/typography';
html,
body {
  font-size: 16px;
  padding: 0 !important;
}

body {
  @include style-text;
  color: rgba(0, 0, 0, 0.87);
  font-family: $font-source-sans-pro;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @include style-heading;
  color: $c-darkblue;
  margin: 0 0 1rem;
}

h1 {
  @include style-heading-1;
  margin: 0 0 1rem;
}

h2 {
  @include style-heading-2;
  margin: 0 0 1rem;
}

h3 {
  @include style-heading-3;
  margin: 0 0 0.75rem;
}

h4 {
  @include style-heading-4;
}

h5 {
  @include style-heading-5;
}

h6 {
  @include style-heading-6;
}

p {
  font-size: inherit;
  font-style: inherit;
  margin: 0 0 1.5rem;
}

strong {
  font-weight: $weight-bold;
}

small {
  @include style-text-small;
}

a {
  &:not([class]) {
    color: $c-darkblue;
    cursor: pointer;
    font-weight: $weight-bold;
    text-decoration: none;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: currentColor;

    &:hover {
      border-bottom-width: 2px;
    }
  }
}

button {
  &:focus {
    outline: none;
  }
}

img,
figure {
  display: block;
}

figcaption {
  @include style-text-small;
  font-style: italic;
  padding: 0.75rem 0;
}

table ul,
table ol {
  @include list-reset;

  &:not([class]) {
    @include style-text;
    margin: 0;
    font-size: inherit;

    li {
      margin: 0 0 0.5rem;
      padding-left: 2.5rem;
      position: relative;

      &::before {
        color: $c-darkblue;
        content: '\002022';
        font-size: 2.25rem;
        left: 6px;
        line-height: 0.8em;
        position: absolute;
        top: 0;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul,
    ol {
      margin: 0.5rem 0 0;
    }
  }
}

ul,
ol {
  @include list-reset;

  &:not([class]) {
    @include style-text;
    margin: 0 0 2.5rem;
    font-size: inherit;

    li:not([data-consent]) {
      margin: 0 0 0.25rem;
      padding-left: 2.5rem;
      position: relative;

      &::before {
        color: $c-darkblue;
        content: '\002022';
        font-size: 2.25rem;
        left: 6px;
        line-height: 0.8em;
        position: absolute;
        top: -4px;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    ul,
    ol {
      margin: 0.5rem 0 0;
    }
  }
}

ol {
  &:not([class]) {
    counter-reset: ol-number;

    li:not([data-consent]) {
      counter-increment: ol-number;
      padding-left: 2rem;

      &::before {
        color: currentColor;
        content: counter(ol-number);
        font-size: inherit;
        font-weight: $weight-bold;
        left: 3px;
        line-height: inherit;
      }
    }
  }
}

q {
  display: inline-block;
  margin: 0 0 1.25rem;
}

blockquote {
  border-left: 6px solid $c-yellow;
  color: $c-darkblue;
  margin: 2rem 0;
  padding: 0 10px 0 1.75rem;
  position: relative;

  & > p {
    font-size: 1.125rem;
    font-style: normal;
    font-weight: $weight-bold;
    margin: 0;
  }
}

dl {
  margin: 0 0 1.25rem;
}

dt {
  @include style-heading;
  @include style-heading-4;
  margin: 0.75rem 0 0;
}

dd {
  @include style-text;
}

table {
  border-collapse: collapse;
  margin: 0 0 1rem;
  text-align: left;
  width: 100%;
}

th,
td {
  border-top: 1px solid transparentize($c-grey, 0.7);
  font-size: 1.125rem;
  padding: 1.5625rem 1.875rem;
}

tbody {
  tr {
    &:nth-child(even) {
      background: $c-grey--lightest;
    }
  }
}

th {
  @include style-heading;
  color: $c-grey--dark;
}

thead {
  th,
  td {
    background-color: $c-yellow;
    border-top: 0;
    color: $c-darkblue;
    font-family: $font-source-sans-pro;
    padding: 1.25rem 1.875rem;
  }
}

tfoot {
  th,
  td {
    border-top: 1px solid $c-grey;
  }
}

fieldset {
  border: 0;
  margin: 0;
  min-width: 0;
  padding: 0;
}

abbr {
  &[title] {
    border-bottom: 1px dotted transparentize($c-grey, 0.5);
    color: $c-grey;
    cursor: help;
    font-weight: $weight-bold;
    text-decoration: none;
    transition: color 0.2s ease-in-out, border-color 0.2s ease-in-out;

    &:hover {
      border-color: $c-grey;
    }
  }
}

mark,
ins {
  background-color: $c-yellow;
  border: 0;
  color: inherit;
  text-decoration: none;
}

del {
  opacity: 0.75;
}

code,
kbd,
tt,
var,
samp,
pre {
  @include style-monospaced;
}

pre {
  border: 2px solid $c-darkblue;
  margin-bottom: 1rem;
  max-width: 100%;
  overflow: auto;
  padding: 0.75rem;
  white-space: pre-wrap;
  word-wrap: break-word;
}

audio,
canvas,
iframe,
svg,
video {
  vertical-align: middle;
}

iframe {
  max-width: 100%;
}

.grecaptcha-badge {
  opacity: 0;
  pointer-events: none;
}
