$spacer: 8px;

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: $spacer !important;
}

.m-2 {
  margin: 2 * $spacer !important;
}

.m-3 {
  margin: 3 * $spacer !important;
}

.m-4 {
  margin: 4 * $spacer !important;
}

.m-5 {
  margin: 5 * $spacer !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: $spacer !important;
  margin-left: $spacer !important;
}

.mx-2 {
  margin-right: 2 * $spacer !important;
  margin-left: 2 * $spacer !important;
}

.mx-3 {
  margin-right: 3 * $spacer !important;
  margin-left: 3 * $spacer !important;
}

.mx-4 {
  margin-right: 4 * $spacer !important;
  margin-left: 4 * $spacer !important;
}

.mx-5 {
  margin-right: 5 * $spacer !important;
  margin-left: 5 * $spacer !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: $spacer !important;
  margin-bottom: $spacer !important;
}

.my-2 {
  margin-top: 2 * $spacer !important;
  margin-bottom: 2 * $spacer !important;
}

.my-3 {
  margin-top: 3 * $spacer !important;
  margin-bottom: 3 * $spacer !important;
}

.my-4 {
  margin-top: 4 * $spacer !important;
  margin-bottom: 4 * $spacer !important;
}

.my-5 {
  margin-top: 5 * $spacer !important;
  margin-bottom: 5 * $spacer !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: $spacer !important;
}

.mt-2 {
  margin-top: 2 * $spacer !important;
}

.mt-3 {
  margin-top: 3 * $spacer !important;
}

.mt-4 {
  margin-top: 4 * $spacer !important;
}

.mt-5 {
  margin-top: 5 * $spacer !important;
}

.mt-6 {
  margin-top: 6 * $spacer !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: $spacer !important;
}

.me-2 {
  margin-right: 2 * $spacer !important;
}

.me-3 {
  margin-right: 3 * $spacer !important;
}

.me-4 {
  margin-right: 4 * $spacer !important;
}

.me-5 {
  margin-right: 5 * $spacer !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: $spacer !important;
}

.mb-2 {
  margin-bottom: 2 * $spacer !important;
}

.mb-3 {
  margin-bottom: 3 * $spacer !important;
}

.mb-4 {
  margin-bottom: 4 * $spacer !important;
}

.mb-5 {
  margin-bottom: 5 * $spacer !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: $spacer !important;
}

.ms-2 {
  margin-left: 2 * $spacer !important;
}

.ms-3 {
  margin-left: 3 * $spacer !important;
}

.ms-4 {
  margin-left: 4 * $spacer !important;
}

.ms-5 {
  margin-left: 5 * $spacer !important;
}

.ms-auto {
  margin-left: auto !important;
}
