// Color system
$white: #fff;
$gray-50: #fafafa;
$gray-100: #f5f5f5;
$gray-200: #eeeeee;
$gray-300: #e0e0e0;
$gray-400: #bdbdbd;
$gray-500: #9e9e9e;
$gray-600: #757575;
$gray-700: #616161;
$gray-800: #424242;
$gray-900: #212121;
$black: #000;

$blue: blue;
$indigo: #081f2c;
$purple: purple;
$pink: pink;
$red: #ee110c;
$orange: orange;
$yellow: #f1be48;
$green: green;
$teal: teal;
$cyan: cyan;

$primary: $yellow;
$secondary: $indigo;
$success: $green;
$info: $blue;
$warning: $yellow;
$danger: $red;
$light: $white;
$dark: $black;

$c-yellow: $yellow;
$c-darkblue: $indigo;
$c-white: $white;
$c-black: $black;

$c-form-main: $indigo;
$c-form-error: $red;
$c-form-valid: $green;

$c-grey--lightest: $gray-100;
$c-grey: $gray-500;
$c-grey--darker: $gray-600;
$c-grey--dark: $gray-700;
$c-greyblue: #1d3748;
