@mixin screen($min-size) {
  @media only screen and (min-width: $min-size) {
    @content;
  }
}

@mixin screen-between($min-size, $max-size) {
  @media only screen and (min-width: $min-size) and (max-width: $max-size - 1) {
    @content;
  }
}

@mixin screen-max($max-size) {
  @media only screen and (max-width: $max-size - 1) {
    @content;
  }
}
