@import '../config/sizes';
@import '../config/colors';
@import '../mixins/container';
@import '../mixins/screen';
@import '../mixins/sr-only';

.menu {
  $self: &;
  background-color: $c-white;
  height: $h-menu;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  transition: background-color .2s, height .2s;
  width: 100%;
  z-index: 10;

  &__container {
    @include container($w-menu-container);
    align-items: center;
    border-bottom: 1px solid transparentize($c-grey--darker, .81);
    display: flex;
    height: 100%;
    justify-content: flex-end;
    padding: 0 $p-gutter--small;
    position: relative;
  }

  &__logo {
    color: $c-yellow;
    display: block;
    flex: 0 0 130px;
    margin-right: auto;
    transition: transform .2s;
    width: 130px;

    &:focus,
    &:hover {
      transform: scale(1.1);
    }
  }

  &__button {
    flex: 0 0 auto;
    width: auto;
  }

  &__contact,
  &__nav {
    display: none;
  }

  &__contact,
  &__nav-secondary {
    flex: 0 0 auto;
  }

  &__nav-secondary {
    align-items: center;
    align-self: flex-end;
    display: flex;
    height: 100%;
    margin-left: .7rem;
  }

  &__toggle {
    margin-left: .7rem;
  }

  &--white {
    background-color: transparent;

    #{ $self }__logo {
      color: $c-white;
    }

    #{ $self }__container {
      border-bottom: 0;
    }

    &.is-sticky {
      background-color: $c-darkblue;
    }
  }

  &--darkblue {
    background-color: $c-darkblue;

    #{ $self }__logo {
      color: $c-white;
    }
  }

  @include screen($screen-minimal) {
    &__contact {
      display: block;
    }
  }

  @include screen($screen-large) {
    height: $h-menu-desktop;
    padding: 0 $p-gutter;

    &__container {
      padding: 0;
    }

    &__logo {
      width: 210px;
    }

    &__toggle {
      display: none;
    }

    &__nav {
      display: block;
      margin: 0 2rem;
    }

    &__nav-secondary {
      margin-left: 1rem;
    }

    &.is-sticky {
      height: $h-menu;

      #{ $self }__logo {
        transform: scale(.9);

        &:focus,
        &:hover {
          transform: scale(1);
        }
      }
    }
  }
}
