@import '../config/colors';
@import '../config/sizes';
@import '../mixins/button';
@import '../mixins/sr-only';
//@import '../mixins/svg';
@import '../mixins/typography';

@mixin nav-dropdown($self) {
  background-color: $c-yellow;
  border-radius: 0 10px 10px;
  display: none;
  margin: 0 -1rem;
  min-width: 250px;
  padding: .75rem 0;
  position: absolute;

  #{ $self }__item {
    margin: 0;
  }

  #{ $self }__link {
    border-radius: 30px 10px 30px 30px;
    color: $c-darkblue;
    margin: 0 .5rem;
    transition: background-color .25s ease-in-out;

    &:hover {
      background-color: transparentize($c-white, .6);

      #{ $self }__text {
        border-bottom: 0;
      }
    }
  }

  #{ $self }__text {
    border-bottom: 0;
    display: inline-block;
    margin: 0 -.5rem;
  }
}

.nav {
  $self: &;

  &__title {
    @include style-heading;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
  }

  &__list {
    margin-bottom: 1rem;
  }

  &__link {
    color: $c-darkblue;
    display: block;
    font-size: 1.125rem;
    font-weight: $weight-medium;
    line-height: 1;
    position: relative;
    text-decoration: none;
    transition: color .25s ease;

    &:hover {
      #{ $self }__text {
        border-bottom-color: $c-yellow;
      }
    }
  }

  &__text {
    border-bottom: 2px solid transparent;
    transition: border-bottom-color .25s ease;
  }

  &__language {
    padding: .8rem 1rem;
    position: absolute;
    right: 0;
    width: 100%;
  }

  &__flag {
    border-radius: 50%;
    height: 20px;
    margin-right: 1rem;
    min-width: 20px;
    overflow: hidden;
    width: 20px;

    img {
      height: 100%;
      object-fit: cover;
      width: 100%;
    }
  }

  &--main {
    #{ $self }__list {
      display: flex;
    }

    #{ $self }__item {
      margin: 3px .5rem 0 .5rem;
    }
  }

  &--drawer {
    margin: 0 (-$p-gutter);

    #{ $self }__link {
      font-size: 1.25rem;
      padding: 1.25rem 5rem 1.25rem $p-gutter;
      transition: color .25s, background-color .25s;

      &.is-active,
      &:hover {
        background-color: $c-yellow;
      }

      &.has-children {
        &::after {
          //@include svg('arrow-down', $c-darkblue);
          content: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="#{$c-darkblue}" d="M11.203 18.65L.33 7.608a1.126 1.126 0 010-1.593l.666-.666c.44-.44 1.153-.44 1.593 0L12 14.938l9.41-9.58c.441-.44 1.154-.44 1.594 0l.666.666c.44.44.44 1.153 0 1.593L12.797 18.66a1.135 1.135 0 01-1.594-.009z"/></svg>';
          height: 16px;
          position: absolute;
          right: 1.5rem;
          top: 24px;
          transition: transform .2s;
          width: 16px;
        }

        &.is-active {
          &::after {
            transform: rotate(180deg);
          }
        }
      }
    }

    #{ $self }__text {
      border-bottom: 0;
    }

    #{ $self }__children {
      display: none;
      max-height: 0;
      overflow: hidden;
      transition: max-height .2s;

      &.is-active {
        display: block;
      }

      &.is-open {
        max-height: 600px;
      }

      #{ $self }__link {
        font-size: 1rem;
        font-weight: $weight-normal;
        padding-bottom: 1rem;
        padding-top: 1rem;
      }
    }
  }

  &--white {
    #{ $self }__link {
      color: $c-white;
    }
  }

  &--footer {
    #{ $self }__item {
      margin: 0 0 1rem;
    }

    #{ $self }__link {
      font-weight: $weight-normal;
      line-height: 1.5;

      &:hover {
        #{ $self }__text {
          border-bottom-color: currentColor;
        }
      }
    }
  }

  &--secondary {
    #{ $self }__list {
      display: flex;
      margin: 0;
    }

    #{ $self }__item {
      margin: 0 4px;

      &--search {
        display: none;
      }

      &--language {
        border-radius: 10% 50% 0 0;
        margin-bottom: -1rem;
        padding-bottom: 1rem;

        &:hover {
          background-color: $c-yellow;

          #{ $self }__button {
            background-color: $c-yellow;
            border-color: $c-yellow;
            color: $c-darkblue;
          }

          #{ $self }__children {
            display: block;
            max-height: fit-content;
          }
        }
      }
    }

    #{ $self }__link {
      align-items: center;
      display: flex;
      margin: 0 -1rem;
      padding: 1rem 1.875rem;
      white-space: nowrap;

      .icon {
        display: none;
        height: 14px;
        margin-left: auto;
        width: 14px;
      }

      &.is-active {
        color: $c-white;

        .icon {
          display: block;
        }
      }
    }

    #{ $self }__children {
      @include nav-dropdown($self);

      border-radius: 10px 30px 30px;
      margin: 0;
      position: initial;
      right: 0;

      #{ $self }__text {
        margin: 0 2rem 0 0;
      }

      #{ $self }__link {
        &.is-active {
          color: $c-white;
        }
      }
    }
  }

  @include screen($screen-simple) {
    &__language {
      width: auto;
    }

    &--secondary {
      &--language {
        position: relative;
      }
    }
  }

  @include screen($screen-large) {
    &__list {
      margin-bottom: 0;
    }

    &--main {
      #{ $self }__link {
        margin: 0 -1rem;
        padding: 1rem 1.875rem;
        white-space: nowrap;
      }

      #{ $self }__text {
        border-bottom-width: 3px;
      }

      #{ $self }__item {
        .has-children {
          border-radius: 10px 30px 0 0;
        }

        &:hover {
          #{ $self }__link {
            &.has-children {
              background-color: $c-yellow;
              color: $c-darkblue;

              #{ $self }__text {
                border-bottom-color: transparent;
              }
            }
          }

          #{ $self }__children {
            display: block;
            max-height: fit-content;
          }
        }
      }

      #{ $self }__children {
        @include nav-dropdown($self);
      }
    }

    &--secondary {
      #{ $self }__item {
        &--search {
          display: block;
        }
      }
    }
  }
}
