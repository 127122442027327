@import '../config/sizes';
@import '../config/colors';
@import '../mixins/center';
@import '../mixins/container';
@import '../mixins/screen';
@import '../mixins/stretch';
@import '../mixins/typography';

.header {
  $self: &;
  background-color: $c-darkblue;
  height: 100vh;
  overflow: hidden;
  position: relative;

  &::before {
    @include stretch;
    background-color: transparentize($c-black, .85);
    content: '';
    z-index: 1;
  }

  &__container {
    @include container($w-section-container);
    align-items: center;
    display: flex;
    height: 100%;
    padding: 0 $p-gutter--small;
    position: relative;
  }

  &__content {
    color: $c-white;
    position: relative;
    z-index: 2;
  }

  &__carousel,
  &__image,
  &__video {
    @include stretch;
    object-fit: cover;
  }

  &__subtitle {
    @include style-heading-subtitle;
    color: $c-white;
    margin: 0 0 .7rem;
  }

  &__title {
    @include style-heading-header;
    color: $c-white;
    line-height: 1.1;
  }

  &__wave {
    bottom: 40px;
    max-width: 900px;
    opacity: .8;
    pointer-events: none;
    position: absolute;
    right: $p-gutter--small;
    transform: rotate(180deg);
    width: 100%;
    z-index: 3;
  }

  &__wave-content {
    margin-right: 80px;
    position: absolute;
    right: 100%;
    top: 20px;
    width: 800px;

    .wave {
      opacity: .58;
    }

    &::after {
      background-color: $c-yellow;
      content: '';
      height: 16px;
      opacity: 1;
      position: absolute;
      right: 0;
      top: 0;
      width: 16px;
    }
  }

  &__more {
    background-color: $c-darkblue;
    bottom: 0;
    color: $c-white;
    height: 60px;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 2;
  }

  &__icon {
    @include center-vertical;
    animation: floating 3s ease infinite;
    fill: currentColor;
    height: 16px;
    left: 30px;
    width: 16px;
  }

  &--left {
    #{ $self }__subtitle {
      @include style-heading-subtitle;
    }

    #{ $self }__title {
      @include style-heading-header;
    }
  }

  &--small {
    height: 400px;

    #{ $self }__subtitle {
      @include style-heading-subtitle-small;
    }

    #{ $self }__title {
      @include style-heading-header-small;
    }
  }

  &--white {
    #{ $self }__more {
      background-color: $c-white;
      color: $c-darkblue;
    }
  }

  &--grey {
    #{ $self }__more {
      background-color: $c-grey--lightest;
      color: $c-darkblue;
    }
  }

  &--yellow {
    #{ $self }__more {
      background-color: $c-yellow;
      color: $c-darkblue;
    }
  }

  &--greyblue {
    #{ $self }__more {
      background-color: $c-greyblue;
    }
  }


  @include screen($screen-minimal) {
    &__container {
      padding: 0 $p-gutter;
    }
  }

  @include screen($screen-normal) {
    &__wave {
      bottom: 46px;
      right: $p-gutter;
    }

    &__more {
      height: 80px;
      width: 79%;
    }

    &__text {
      font-size: 1.375rem;
    }

    &__icon {
      height: 20px;
      left: 60px;
      width: 20px;
    }

    &--left {
      #{ $self }__content {
        max-width: 800px;
      }

      #{ $self }__text {
        max-width: 750px;
      }

      #{ $self }__more {
        width: 50%;
      }
    }
  }

  @include screen($screen-large) {
    &__more {
      height: 94px;
    }
  }

  @include screen($screen-xlarge) {
    &--small {
      height: 450px;
    }
  }

  @include screen($screen-jumbo) {
    &__wave-content {
      margin-right: 160px;
    }
  }
}
